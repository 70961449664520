<template>
  <v-navigation-drawer permanent mini-variant :mini-variant-width="isMobileTest() ? 56 : 88" class="menu py-3" touchless
    app>
    <div class="menu-header ">
      <div class="menu-user text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <AppUserCard v-bind="attrs" v-on="on" />
          </template>
          <span>Configurações do usuário</span>
        </v-tooltip>
      </div>
    </div>

    <div class="-contentmenu">
      <v-list class="style-menu">
        <v-list-item v-for="item in filteredMenuItems" v-bind:key="item.id" class="tw-w-full">
          <router-link :to="item.routeName">
            <!-- <v-tooltip>
              <template v-slot:activator="{ on, attrs }"> -->
            <v-list-item-action class="justify-center align-center tw-pt-4">
              <!-- <v-badge 
                    v-if="item.routeName == '/pedidos' && registered.length > 0"
                    color="green"
                    :content="registered.length"
                  >
                    <component
                      :class="{'icon-selected': item.routeName === $route.path}"
                      :is="item.icon"
                      :width="20"
                      :height="20"
                    ></component>
                  </v-badge> -->
              <component :is="item.icon" :class="{ 'icon-selected': $route.path.includes(item.routeName) }" :width="20"
                :height="20" id="menuIcons"></component>

              <LineBorder left size="3px" height="52px" :fadeIn="true" top="5px" color="primary"
                v-show="$route.path.includes(item.routeName)"></LineBorder>
              <span :class="{
                'titles-menu-buttons-selected':
                  $route.path.includes(item.routeName),
              }" class="titles-menu-buttons hidden-sm-and-down">{{ item.title }}
              </span>
            </v-list-item-action>
            <!-- </template>
              
            </v-tooltip> -->
          </router-link>
        </v-list-item>
        <!-- <a :href="company.linkFinancialPanel" target="_blank">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  title="Financeiro"
                  class="finances-icon"
                  src="../../assets/img/financial_icon.png"
                  alt="Financeiro"
                />
              </template>
              <span>Financeiro</span>
            </v-tooltip>
          </a> -->
      </v-list>
    </div>
    <div class="menu-footer text-center">
      <!-- <img 
        width="25"
        src="../../assets/img/logo_vertical.png"
        alt="Gerenciei"
      />  -->

    </div>
  </v-navigation-drawer>
</template>

<script>
import CompanyService from '../../modules/configurations/services/configuration-service';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('orders');
const { mapState: mapStateUsers } = createNamespacedHelpers('users');

export default {
  methods: {
    ...mapActions(['playNotification', 'stopNotification']),
  },
  computed: {
    ...mapGetters(['registered']),
    ...mapStateUsers(['loggedUser']),
    filteredMenuItems() {
      if (!this.loggedUser || !this.loggedUser.role) return [];
      if (this.loggedUser.role.name == 'Administrador') return this.items;
      else
        return this.items.filter((item) => {
          return item.filter(
            this.loggedUser.role.permissions.map(({ permission }) => permission)
          );
        });
    },
  },
  watch: {
    registered(newValue) {
      if (newValue.length > 0) {
        this.playNotification();
      } else {
        this.stopNotification();
      }
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: 'PDV',
          icon: 'cash-register',
          routeName: '/pdv',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('view_pdv') > -1;
          },
        },
        /* {
          id: 2,
          title: 'Pedidos',
          icon: 'orders-icon',
          routeName: '/pedidos',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('use_orders') > -1;
          },
        }, */
        {
          id: 2,
          title: 'Financeiro',
          icon: 'money-check-icon',
          routeName: '/financeiro',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('view_sale_historic') > -1;
          },
        },
        {
          id: 3,
          title: 'Clientes',
          icon: 'client-icon',
          routeName: '/clientes',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('view_clients') > -1;
          },
        },
        {
          id: 4,
          title: 'Produtos',
          icon: 'product-icon',
          routeName: '/produtos',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('view_products') > -1;
          },
        },
        {
          id: 5,
          title: 'Notas fiscais',
          icon: 'file-invoice-dollar',
          routeName: '/nota-fiscal',
          exact: true,
          filter(permissions) {
            return permissions.indexOf('view_nfe') > -1;
          },
        },
        {
          id: 6,
          title: 'Configurações',
          icon: 'cog-icon',
          routeName: '/configuracoes',
          exact: true,
          filter(permissions) {
            return false;
          },
        },

      ],
      company: {},
    };
  },
  async created() {
    try {
      const companyId = window.localStorage.getItem('companyId');
      const response = await CompanyService.get(companyId);
      this.company = response.data;
    } catch (err) {
      console.log(err.response);
    }
  },
};
</script>

<style>
.logout-button {
  transform: rotate(180deg);
}

.v-navigation-drawer .v-navigation-drawer__border {
  background-color: var(--v-border-base) !important;
}

.v-navigation-drawer .v-list-item__action {
  margin: 0px !important;
}

.menu-icons.v-icon {
  font-size: 20px !important;
  color: #ffffff !important;
}

.v-navigation-drawer.menu .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-footer {
  display: grid;
  justify-items: center;
  padding-bottom: 13px;
}

.menu-user {
  display: grid;
  justify-items: center;
  padding-top: 13px;
}

.finances-icon {
  width: 18px;
  height: 20px;
}

.style-menu {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

.menu-header {
  display: table;
  text-align: center;
}

.titles-menu-buttons {
  font-size: 11px;
  color: #302d2d;
  margin-top: 0.1rem;
}

.titles-menu-buttons-selected {
  color: #006aff;
}

.icon-selected,
.icon-selected .a,
.icon-selected .d,
.icon-selected .c,
.icon-selected .ccc {
  stroke: #006aff !important;
  color: #006aff !important;
}
</style>
