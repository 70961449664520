import apiClient from '@/utils/api-client-without-base-url';
import apiAppClient from '@/utils/api-client-apiapp';


const suffixEndpoint = '/orders';

export default {
  getAll: (page, limit, order, way, date, mode, type, nfMod, cashBoxId, vendor, search, isSketchs) => apiClient.get(`${suffixEndpoint}`, { params: { page, limit, order, way, date, mode, type, nfMod, cashBoxId, vendor, search, isSketchs } }),
  getLastSales: (date) => apiClient.get(`${suffixEndpoint}/last-sales`, { params: { date } }),
  getOrdersTableLength:(date, mode, type, nfMod, cashBoxId, vendor, search, isSketchs) => apiClient.get(`${suffixEndpoint}/tables-length`, { params: { date, mode, type, nfMod, cashBoxId, vendor, search, isSketchs } }),
  get: id => {
    return apiClient.get(`${suffixEndpoint}/${id}`);
  },
  getVendorData: (vendorId, date, mode) => {
    console.log(vendorId)
    return apiClient.get(`${suffixEndpoint}/vendor/${vendorId}`, { params: { date, mode }});
  },
  getOrdersCancelReasons: () => apiClient.get('/orders-cancel-reasons'),
  getLatest: (lastId) => apiClient.get(`${suffixEndpoint}/latest/${lastId}`),
  create: data => {
    return apiClient.post(suffixEndpoint, data)
  },
  cancelNf: data => {
    return apiClient.put(suffixEndpoint + "/cancel", data)
  },
  edit: data => {
    return apiAppClient.put(`${suffixEndpoint}/${data.id}`, data)
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`)
  },
  cancelOrderAndBills: id => {
    return apiClient.put(`${suffixEndpoint}/cancel-order-and-bills/${id}`)
  },
  sendAllContingency: () => {
    return apiClient.post(`${suffixEndpoint}/send-all-contingency`);
  }
  // contingencyOrders: () => {
  //   return apiClient.get(`${suffixEndpoint}/allContingencies`)
  // }
};
