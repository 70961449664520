<template>
  <div class="page-header">
    <v-row class="tw-flex tw-flex-col md:tw-flex-row">
      <v-col cols="auto" class="tw-pb-0">
        <v-btn icon large circle rounded @click="$router.back()"><v-icon>la-angle-left</v-icon></v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pr-0 tw-text-center "
        :class="{'tw-pt-0': isMobileTest()}"
      >
        <h1 class="title-page">{{title}}</h1>
      </v-col>
      <slot></slot>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: ''
    }
  }
};
</script>
<style>
.title-page {
  display: inline-block;
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 1.7;
}
.title-page-2 {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7;
}

.page-header {
  margin-top: 20px;
  padding: 0px 40px;
}
@media (max-width: 768px){
.page-header {
  margin-top: 10px;
  padding: 0px 10px;
}
.title-page {
  font-size: 1.5rem;
   font-weight: 400;
}
}
</style>
