const STATUS = {
  RECEIVED: 1,
  IN_PREPARATION: 2,
  IN_PROGRESS: 3
};

const TYPES = {
  "1": 'e-commerce',
  "2": "PDV"
};

const STATUS_NAME = {
  1: 'Pendente',
  2: 'Em preparação',
  3: 'A caminho',
  6: 'Aguardando retirada',
  4: 'Finalizado',
  5: 'Cancelado',
};

const STATUS_ARRAY = [
  { id: 1, text: 'Pendente', backgroundColor: '#ee8791' },
  { id: 2, text: 'Em preparação', backgroundColor: '#5ACEE4' },
  { id: 3, text: 'A caminho', backgroundColor: '#E45ACD' },
  { id: 6, text: 'Aguardando retirada', backgroundColor: '#A345D9' },
  { id: 4, text: 'Finalizado', backgroundColor: '#5AE47E' },
  { id: 5, text: 'Cancelado', backgroundColor: '#A9A9A9' },
];

const PAYMENT_METHODS = [
  'Cartão no aplicativo',
  'Cartão na entrega',
  'Dinheiro',
];

const NF_TYPES = {
  "1": 'NFC-e',
  "2": 'NF-e',
  "3": "Cupom não fiscal"
}


const PAYMENT_METHODS_NF = [
  {
    id: '01',
    icon: 'la-dollar-sign',
    name: 'Dinheiro',
  },
  {
    id: '915',
    icon: 'la-credit-card',
    name: 'Crediário'
  },
  {
    id: '03',
    icon: 'la-credit-card',
    name: 'Cartão de crédito',
  },
  {
    id: '04',
    icon: 'la-credit-card',
    name: 'Cartão de débito',
  },
  {
    id: '02',
    icon: 'la-money-check',
    name: 'Cheque',
  },
  // {
  //   id: '05',
  //   icon: 'la-hand-holding-usd',
  //   name: 'Crédito loja',
  // },
  {
    id: '20',
    icon: 'la-hand-holding-usd',
    name: 'Crédito na loja',
  },
  {
    id: '10',
    icon: 'la-shopping-basket',
    name: 'Vale alimentação',
  },
  {
    id: '11',
    icon: 'la-utensils',
    name: 'Vale refeição',
  },
  {
    id: '12',
    icon: 'la-gift',
    name: 'Vale presente',
  },
  {
    id: '13',
    icon: 'la-gas-pump',
    name: 'Vale combustível',
  },
  {
    id: '15',
    icon: 'la-barcode',
    name: 'Boleto',
  },
  {
    id: '16',
    icon: 'la-arrow-alt-circle-down',
    name: 'Depósito bancário',
  },
  {
    id: '17',
    icon: 'la-exchange-alt',
    name: 'PIX',
  },
  {
    id: '18',
    icon: 'la-exchange-alt',
    name: 'Transferência bancária ou carteira digital',
  },
  {
    id: '19',
    icon: 'la-undo-alt',
    name: 'Cashback, Crédito virtual ou fidelidade',
  },
];

export { PAYMENT_METHODS_NF, TYPES, STATUS, STATUS_NAME, STATUS_ARRAY, PAYMENT_METHODS, NF_TYPES };
