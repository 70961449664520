var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-main',{class:{
      'content-padding':
        _vm.isAppMenuVisible &&
        !_vm.$route.path == '/login' &&
        _vm.isMobileTest() == false,
      'content-padding-mobile': _vm.isAppMenuVisible && _vm.isMobileTest() != false,
    }},[(_vm.isLoggedIn)?_c('keep-alive',{ref:"keep",attrs:{"include":"page"}},[(_vm.loggedUser && _vm.loggedUser.id)?_c('AppLayout',[[(!(_vm.$route.path == '/pdv'))?_c('keep-alive',[_c('router-view')],1):_vm._e(),(_vm.$route.path == '/pdv')?_c('router-view'):_vm._e()]],2):_vm._e()],1):_c('router-view')],1),_c('v-snackbar',{attrs:{"app":"","bottom":"","value":_vm.isSnackbarVisible,"color":_vm.snackbarColor},on:{"input":(e) => _vm.setIsSnackbarVisible({ isVisible: e })}},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('span',[_vm._v(_vm._s(_vm.snackbarMessage))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.setIsSnackbarVisible({ isVisible: false })}}},[_c('v-icon',{attrs:{"color":_vm.snackbarColor == 'primary' ? 'white' : 'red'}},[_vm._v("la-close")])],1)],1)]),_c('audio',{attrs:{"id":"player","muted":"","loop":""}},[_c('source',{attrs:{"src":require("./modules/orders/store/sound-notification.mp3"),"type":"audio/mp3"}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }