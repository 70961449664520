import ConfigurationService from './../services/configuration-service';

import * as types from './mutation-types';

export default {
  certificateValidations: ({ commit }) => {
    const data = ConfigurationService.certificateValidations()
    return data
  },
  editConfig: async ({ commit }, { company }) => {
    const { data } = await ConfigurationService.edit(company);
    commit(types.EDIT_CONFIG, { company: data });
  },
  getCompany: async ({ commit }) => {
    const { data } = await ConfigurationService.get();
    console.log(data)
    commit(types.EDIT_CONFIG, { company: data });
  },
};
