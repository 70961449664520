const Container = () => import('../views/Container');

const OrdersInitialPage = () => import('../views/OrdersInitialPage');

export default [
  {
    path: '/pedidos',
    component: Container,
    meta: { requiresAuth: true, permissions: ['use_orders'] },
    children: [
      {
        path: '',
        name: 'orders-page',
        component: OrdersInitialPage
      },
    ]
  }
];
