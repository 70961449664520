import { mapCashBoxes, mapCashBox } from './../utils/helpers/cash-boxes';

import { PAYMENT_METHODS_NF } from '../../../utils/enums/index';

import PdvService from '../../pdv/services/pdv-service';

import moment from 'moment';

moment.locale('pt-br');

/* eslint eqeqeq: "off" */

function getLastOpenedCashbox(cashBox) {
  let lastOpenedIndex = 0,
    balance = 0,
    openCashBoxDate;
  for (let i = 0; i < cashBox.operations.length; i++) {
    const register = cashBox.operations[i];

    if (register.type == '1') {
      lastOpenedIndex = i;
      balance = register.value;
      openCashBoxDate = register.createdAt;
    }
  }

  return { lastOpenedIndex, balance, openCashBoxDate };
}

export default {
  cashBoxes: state => mapCashBoxes(state.cashBoxes),
  getCashBox: state => id => state.cashBoxes.find(cashbox => cashbox.id == id),
  cashBoxOperationsLength: state => (state.cashBoxOperationsLength),
  userCashBox: (state, getters, rootState, rootGetters) => {
    // const user = rootState.users.loggedUser;
    const user = state.loggedCashBox

    if (!user) return null;

    // return state.cashBoxes.find(cashbox => cashbox.userId == user.id);
    return user
  },
  // balances: (state, getters, rootState, rootGetters) => {
    // const cashBox = getters.userCashBox;
    // // alert(cashBox + " dasdas" )
    // // console.log(rootGetters);
    // if (!cashBox) return null;

    // let {
    //   lastOpenedIndex,
    //   balance: initialBalance,
    //   openCashBoxDate
    // } = getLastOpenedCashbox(cashBox);

    // let addedBalance = 0,
    //   saleBalance = 0,
    //   subtractedBalance = 0;

    // const indexes = [];

    // const payments = PAYMENT_METHODS_NF.map((pay, index) => {
    //   indexes[pay.id] = index;

    //   return { ...pay, amount: 0 };
    // });

    // payments[indexes['01']].amount = initialBalance;
    // console.log(cashBox)
    // for (let j = lastOpenedIndex; j < cashBox.operations.length; j++) {
    //   const register = cashBox.operations[j];

    //   if (register.type == '2') {
    //     addedBalance += register.value;
    //     payments[indexes['01']].amount += register.value;
    //   }
    //   if (register.type == '3') {
    //     subtractedBalance += register.value;
    //     payments[indexes['01']].amount -= register.value;
    //   }
    // }

    // // state.devolutions.map((devolution) => {
    // //   subtractedBalance += devolution.value * devolution.quantity;
    // //   payments[indexes['01']].amount -= devolution.value * devolution.quantity;
    // // })

    // const cashBoxOrders = rootGetters['orders/getCashBoxOrders'](
    //   cashBox.id,
    //   false
    // );
    //   console.log(cashBoxOrders)
    // for (const order of cashBoxOrders) {
    //   if (moment(order.createdAt).isAfter(openCashBoxDate) && order.statusId != 5) {
    //     for (const pay of order.paymentMethods) {
    //       payments[indexes[pay.paymentCode]].amount += pay.amountPaid;
    //     }

    //     saleBalance += order.amount;
    //   }
    // }

    // return {
    //   initialBalance,
    //   balancesByPaymentMethods: payments,
    //   addedBalance,
    //   subtractedBalance,
    //   saleBalance
    // };
  // },

  isCashBoxOpened: (state, getters, rootState, rootGetters) => {
    const cashBox = state.loggedCashBox;

    if (!cashBox) return null;

    let isOpened = false;
    if (cashBox.lastOperation) {
      if (cashBox.lastOperation.type == '4') {
        isOpened = false;
      }
      else {
        isOpened = true;
      }
    }
    return isOpened;
  },
  cashBoxesOpen: (state, getters, rootState, rootGetters) => (id) => {
    let cashBox = getters.getCashBox(id);

    if (!cashBox) return false;
    cashBox = mapCashBox(cashBox)
    
    let register = cashBox.lastOperation
    if (register != null && register != undefined) {
      if (register.type == '4') {
        cashBox.status = 'Fechado';
      } else {
        cashBox.status = 'Aberto';
      }
    } else {
      cashBox.status = 'Fechado';
    }
    return cashBox;
  },
};
