import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/clients';

export default {
  getAll: () => apiClient.get(suffixEndpoint),
  get: (id, companyId) => {
    console.log(id, companyId);
    return apiClient.get(`${suffixEndpoint}/${id}/company/${companyId}`);
  },
  create: (data) => {
    return apiClient.post(suffixEndpoint, data);
  },
  edit: (data) => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
  },
  delete: (id) => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  },
  getAllSalesClientLength: (clientId) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/all-sales-length`);
  },
  getAllSalesClient: (clientId, page, limit, way) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/all-sales`, {
      params: { page, limit, way },
    });
  },
  getAvailableDebitLimit: (clientId) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/available-debit-limit`);
  },
};
