import { mapFinancialTransactions } from '../utils/helpers/index';
// import { getDevolution } from '../../../utils/helpers/devolutions';
import moment from 'moment';
moment.locale('pt-br');

const isBetweenDates = (dateToVerify, start, end) => {
  return (
    moment(dateToVerify).isSameOrAfter(moment(start).startOf('day')) &&
    moment(dateToVerify).isSameOrBefore(moment(end).endOf('day'))
  );
};

const calculateBillCountByCategory = (bills, filters) => {
  const categoryBillCount = {};

  for (let i = 0; i < bills.length; i++) {
    const bill = bills[i];
    if (
      bill.categoryId &&
      bill.type == filters.type &&
      isBetweenDates(bill.expirationDate, filters.date[0], filters.date[1]) &&
      (filters.accountId > 0 ? bill.accountId == filters.accountId : true)
    ) {
      if (!categoryBillCount['' + bill.categoryId]) {
        categoryBillCount['' + bill.categoryId] = 0;
      }

      categoryBillCount['' + bill.categoryId]++;
    }
  }

  return categoryBillCount;
};

const calculateTransactionsCountByCategory = (
  financialTransactions,
  filters
) => {
  const categoryTransactionCount = {};

  for (let i = 0; i < financialTransactions.length; i++) {
    const transaction = financialTransactions[i];
    if (
      transaction.categoryId &&
      isBetweenDates(transaction.createdAt, filters.date[0], filters.date[1]) &&
      (filters.accountId > 0
        ? transaction.accountId == filters.accountId
        : true)
    ) {
      if (!categoryTransactionCount['' + transaction.categoryId]) {
        categoryTransactionCount['' + transaction.categoryId] = 0;
      }

      categoryTransactionCount['' + transaction.categoryId]++;
    }
  }

  return categoryTransactionCount;
};

export default {
  financialCategories: (state) => state.financialCategories,
  financialCategoriesWithFinancialTransactionsCount: (state) => (filters) => {
    const categoryFinancialTransactionsCount =
      calculateTransactionsCountByCategory(state.financialTransactions, {
        ...filters,
      });

    return state.financialCategories.map((category) => {
      return {
        ...category,
        financialTransactionsCount:
          categoryFinancialTransactionsCount['' + category.id] || 0,
      };
    });
  },
  financialCategoriesWithBillToPayCount: (state) => (filters) => {
    const categoryBillCount = calculateBillCountByCategory(state.bills, {
      type: 'S',
      ...filters,
    });

    return state.financialCategories
      .filter(({ type }) => type == 'S')
      .map((category) => {
        return {
          ...category,
          billCount: categoryBillCount['' + category.id] || 0,
        };
      });
  },
  financialCategoriesWithBillToReceiveCount: (state) => (filters) => {
    const categoryBillCount = calculateBillCountByCategory(state.bills, {
      type: 'E',
      ...filters,
    });

    return state.financialCategories
      .filter(({ type }) => type == 'E')
      .map((category) => {
        return {
          ...category,
          billCount: categoryBillCount['' + category.id] || 0,
        };
      });
  },
  // getFinancialTransactionByClient: state => id =>{
  //   return mapFinancialTransactions(state.financialTransactions.filter(transaction => transaction.clientId == id))
  // },
  financialTransactions: (state) =>
    mapFinancialTransactions(state.financialTransactions),
  // icomesToCancel: state =>
  //   mapFinancialTransactions(state.financialTransactions.filter(({ type, order }) => type == 'E' && order)),
  expenses: (state) =>
    state.financialTransactions.filter(({ type }) => type == 'S'),
  incomes: (state) =>
    state.financialTransactions.filter(({ type }) => type == 'E'),
  notCanceledIncomes: (state) =>
    state.financialTransactions.filter(
      ({ order, type }) => order && order.statusId != 5 && type == 'E'
    ),
  filterFinancialTransactionsByAccount:
    (state) =>
    (accountId, datesToFilter = []) =>
      mapFinancialTransactions(
        state.financialTransactions.filter((t) => {
          if (datesToFilter && datesToFilter.length == 2) {
            if (
              !isBetweenDates(t.createdAt, datesToFilter[0], datesToFilter[1])
            ) {
              return false;
            }
          }

          if (!accountId) return true;

          return t.accountId == accountId;
        })
      ),
  getAccountBalance:
    (state) =>
    (accountId, datesToFilter = []) => {
      let balance = 0;

      const transactions = state.financialTransactions.filter((t) => {
        if (datesToFilter && datesToFilter.length == 2) {
          if (!isBetweenDates(t.createdAt, datesToFilter[0], datesToFilter[1]))
            return false;
        }

        if (t.accountId == accountId || accountId == null) {
          if (t.type == 'S') balance -= t.value;
          else if (t.type == 'E') balance += t.value;
          else if (t.type == 'D') {
            // let devolution = getDevolution(state.devolutions, t)
            // let devolution = state.devolutions.filter(({financialTransactionId} ) => {       })
            //   return financialTransactionId == t.id
            //  balance -= t.value * devolution[0].quantity
            balance -= t.value;
          }
        }

        return t.accountId == accountId;
      });

      return balance;
    },
  getExpensesBalanceByAccount:
    (state) =>
    (accountId, datesToFilter = []) => {
      let balance = 0;

      const transactions = state.financialTransactions.filter((t) => {
        if (datesToFilter && datesToFilter.length == 2) {
          if (!isBetweenDates(t.createdAt, datesToFilter[0], datesToFilter[1]))
            return false;
        }

        if (t.accountId == accountId || accountId == null) {
          if (t.type == 'S') balance += t.value;
          else if (t.type == 'D') {
            //  let devolution = getDevolution(state.devolutions, t)
            // balance += t.value * devolution[0].quantity
            balance += t.value;
          }
        }

        return t.accountId == accountId;
      });

      return balance;
    },
  getIncomeBalanceByAccount:
    (state) =>
    (accountId, datesToFilter = []) => {
      let balance = 0;

      const transactions = state.financialTransactions.filter((t) => {
        if (datesToFilter && datesToFilter.length == 2) {
          if (!isBetweenDates(t.createdAt, datesToFilter[0], datesToFilter[1]))
            return false;
        }

        if (t.accountId == accountId || accountId == null) {
          if (t.type == 'E') balance += t.value;
        }

        return t.accountId == accountId;
      });

      return balance;
    },
  finalBalance: (state) => {
    let balance = 0;

    for (const t of state.financialTransactions) {
      if (t.type == 'E') balance += t.value;
      else if (t.type == 'S') balance -= t.value;
      else if (t.type == 'D') {
        // let devolution = getDevolution(state.devolutions, t)
        //  balance -= t.value * devolution[0].quantity
        balance -= t.value;
      } else if (t.type == 'Q') balance += t.value;
    }

    return balance;
  },
  incomeBalance: (state) => {
    let balance = 0;

    for (const t of state.financialTransactions) {
      if (t.type == 'E') balance += t.value;
      if (t.type == 'D') balance -= t.value;
    }

    return balance;
  },
  balanceReport: (state) => {
    let date = moment();
    let months = [];

    let balancesByMonth = [];
    const monthIndex = {};

    for (let i = 9; i >= 0; i--) {
      balancesByMonth.push(0);
      monthIndex[date.format('MMM')] = i;

      months.push(date.format('MMM'));
      date = date.subtract(1, 'months');
    }

    months = months.reverse();

    for (const t of state.allFinancialTransactions) {
      const transactionMonth = moment(t.createdAt).format('MMM');

      if (
        moment(t.createdAt).diff(moment(), 'months') < 12 &&
        monthIndex[transactionMonth] >= 0
      ) {
        if (t.type == 'E') {
          balancesByMonth[monthIndex[transactionMonth]] += t.value;
          if (t.order && t.order.statusId == 5) {
            balancesByMonth[monthIndex[transactionMonth]] -= t.value;
          }
        } else if (t.type == 'S' || t.type == 'D') {
          balancesByMonth[monthIndex[transactionMonth]] -= t.value;
        }
      }
    }

    return {
      data: balancesByMonth,
      months,
    };
  },
  incomesReport: (state) => {
    let date = moment();
    let months = [];

    const currentYear = moment().format('YYYY');

    let incomesByMonth = [];
    const monthIndex = {};

    for (let i = 9; i >= 0; i--) {
      incomesByMonth.push(0);
      monthIndex[date.format('MMM')] = i;

      months.push(date.format('MMM'));
      date = date.subtract(1, 'months');
    }

    months = months.reverse();
    for (const t of state.allFinancialTransactions) {
      const transactionMonth = moment(t.createdAt).format('MMM');

      if (
        t.type == 'E' &&
        moment(t.createdAt).diff(moment(), 'months') < 12 &&
        monthIndex[transactionMonth] >= 0
      ) {
        incomesByMonth[monthIndex[transactionMonth]] += t.value;
      }
      if (
        t.type == 'D' &&
        moment(t.createdAt).diff(moment(), 'months') < 12 &&
        monthIndex[transactionMonth] >= 0
      ) {
        incomesByMonth[monthIndex[transactionMonth]] -= t.value;
      }

      if (
        t.type == 'E' &&
        t.order &&
        t.order.statusId == 5 &&
        moment(t.createdAt).diff(moment(), 'months') < 12 &&
        monthIndex[transactionMonth] >= 0
      ) {
        incomesByMonth[monthIndex[transactionMonth]] -= t.value;
      }
    }
    return {
      data: incomesByMonth,
      months,
    };
  },
  expensesBalance: (state) => {
    let balance = 0;

    for (const t of state.financialTransactions) {
      if (t.type == 'S') balance += t.value;
      // else if(t.type == 'D'){
      //   let devolution = getDevolution(state.devolutions, t)
      //  balance += t.value * devolution[0].quantity
      // }
    }

    return balance;
  },
  devolutions: (state) => {
    return state.devolutions;
  },
  getCashBoxDevolutions:
    (state) =>
    (cashBoxId, mask = true) => {
      const devolutions = state.devolutions.filter(
        (d) => d.cashBoxId == cashBoxId
      );

      // if (mask) return mapOrders(orders);

      return devolutions;
    },
};
