<template>
  <v-text-field ref="inputRef" v-bind="$attrs" :value="formattedValue" hide-details outlined dense />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'

export default {
  name: 'CurrencyInput',
  props: {
    value: Number,
    options: Object
  },
  methods:{
    focus(){
      this.$refs.inputRef.focus()
    },
  },
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput({ ...props.options, currency: 'BRL', autoDecimalDigits: true })
   
    watch(() => props.value, (value) => {
      setValue(value)
    })

    return { inputRef, formattedValue }
  }
}
</script>