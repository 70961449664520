import { CLIENT_ENUM } from './../names';
import { maskBr, validateBr } from 'js-brasil';

import { mapBills } from '@/modules/financial/submodules/bills/utils/helpers';

import { isPaidBill } from '@/modules/financial/submodules/bills/utils/helpers';

import moment from 'moment';
moment.locale('pt-br');

import { currency } from '@/utils/helpers/currency';

const mapClient = (client, allPendingBills = [], clientsDebitGroups = []) => {
  let cpfCnpjMasked = '',
    clientsDebitGroup = null;

  if (client.cpfCnpj) {
    cpfCnpjMasked = validateBr.cnpj(client.cpfCnpj)
      ? maskBr.cnpj(client.cpfCnpj)
      : validateBr.cpf(client.cpfCnpj)
        ? maskBr.cpf(client.cpfCnpj)
        : '';
  }

  const clientBills = allPendingBills.filter(
    ({ clientId }) => clientId == client.id
  );

  if (client.clientsDebitGroupId > 0) {
    const debitGroup = clientsDebitGroups.find(
      ({ id }) => id == client.clientsDebitGroupId
    );

    if (debitGroup) clientsDebitGroup = { ...debitGroup };
  }

  const installmentsPlan = clientBills.filter(
    bill => bill.order && (bill.order.nfMod == 65 || bill.order.nfMod == null)
  );

  const availableDebitLimit = clientsDebitGroup
    ? calculateAvailableDebitLimit(
      clientsDebitGroup.debitLimit,
      installmentsPlan
    )
    : 0;

  // console.log(availableDebitLimit);

  return {
    ...client,
    phone: maskBr.telefone(client.phone),
    cpfCnpj: cpfCnpjMasked,
    birthday: maskBr.data(client.birthday),
    type: CLIENT_ENUM[client.type].TITLE_SINGULAR,
    bills: clientBills,
    installmentsPlan,
    clientsDebitGroup,
    availableDebitLimit,
    ref: { ...client }
  };
};

const calculateAvailableDebitLimit = (limit, installmentsPlan) => {
  return (
    limit -
    installmentsPlan.reduce((accum, current) => {
      if (!isPaidBill(current.ref || current))
        return accum + (current.ref || current).value;

      return accum;
    }, 0)
  );
};

const mapClients = (clients, clientsDebitGroups, allPendingBills = []) => {
  return clients.map(client =>
    mapClient(client, allPendingBills, clientsDebitGroups)
  );
};

// const totalDebit = (clients) => {
//   let totalDebits;
//     clients.map((client) => {
//       totalDebits = client.bills.map((bill) => {
//         if (
//           bill.type == 'E' &&
//           !isPaidBill(bill) &&
//           moment().isAfter(moment(bill.expirationDate))
//         ) {
//           totalDebits = bill.value.replace('R$', '');
//           totalDebits = totalDebits.replace('.', '');
//           totalDebits = totalDebits.replace(',', '.');
//           return parseFloat(totalDebits);
//         }
//       });
//       totalDebits = totalDebits.filter((x) => x !== undefined);
//       if (totalDebits.length) {
//         totalDebits = totalDebits.reduce(function (soma, i) {
//           return soma + i;
//         });
//       }
//       if (totalDebits > 0) { client.totalDebit = '- ' + currency(totalDebits) }
//     });
//     return clients
// }

export { mapClients, mapClient, calculateAvailableDebitLimit };
